<template>
	<div id="login">
		<div class="col-8 imagem background animated">
			<LoginEsquerda/>
		</div>
		<div class="col-12 col-md-4 lateral">
			<div class="col-12 col-lg-12">
				<Logo/>
			</div>
			<v-form ref="form_usuario">
				<div class="col-12 row">
					<div class="col-12">
						<h2>
							Digite seu email:
						</h2>
					</div>
					<div class="col-12">
						<v-text-field type="email" :rules="[v => !!v || 'Campo obrigatório']" v-model="input.email" label="Usuário" placeholder="Usuário" outlined/>
					</div>
					<div class="col-5 offset-6 recuperar-senha">
						<router-link to="/login">Voltar</router-link>
					</div>
					<div class="col-12">
						<v-btn type="submit" block elevation="2" large class="primary-button" :loading="loading" >
							<span>Enviar</span> 
						</v-btn>
					</div>
				</div>
			</v-form>
			<Loader v-if="loading"/>
		</div>
	</div>
</template>

<script>
	import Logo from "@/components/Logo.vue"
	import LoginEsquerda from "@/components/LoginEsquerda.vue"
	import Loader from "@/components/Loader.vue"
	import store from '@/store'
	
	export default {
		name: 'RecuperarSenha',
		components: {
			Logo,
			Loader,
			LoginEsquerda
		},
		data() {
            return {
				loading: false,
                input: {
                    email: ""
                },
                validacao: {
                    email: false
                }
            }
        },
        methods: {
			changeLoading(value) {
				this.loading = value
			},
			handleSubmit() {
				this.changeLoading(true)
                if(this.input.email != "") {
					store.dispatch('recuperarSenha', this.input).then(()=>{
						this.changeLoading(false)
						this.validacao.email = false
					})
                } else {
					this.changeLoading(false)
                    this.validacao.email = true
                }
            }
        }
	}
</script>

<style lang="scss">
	#login{
		flex: 1;
		display: flex;
		max-height: 100vh;
		.lateral{
			display: flex;
			flex-wrap: wrap;
			justify-content: start;
			align-items: center;
			align-content: flex-start;
			padding-right: 100px;
			form{
				h2{					
					font-style: normal;
					font-weight: 500;
					font-size: 22px;
					line-height: 36px;
					color: var(--primary-color);
				}

				fieldset{
					border: 2px solid #b0b0b0;
					border-radius: 5px;
				}	

				.lembrar-usuario{
					display: flex;
					align-items: center;
					label{
						display: flex;
						align-items: center;
						margin-bottom: 0;
						p{
							padding: 0px;
							font-style: normal;
							font-weight: normal;
							font-size: 14px;
							line-height: 17px;
							color: #373737;
						}
						input{
							width: auto;
							margin-right: 10px;
						}
					}
				}
				.recuperar-senha{
					display: flex;
					align-items: center;
					justify-content: flex-end;
					text-align: right;
					a{
						text-align: right;
						font-style: normal;
						font-weight: normal;
						font-size: 16px;
						line-height: 20px;
						color: rgba(0,0,0,.6);
					}
				}
				@media (max-width: 1400px){
					padding: 0 15px;
				}

				button {
					background: var(--primary-color);
					color: #fff;
					box-shadow: -4px -4px 12px rgba(253, 255, 255, 0.8), 4px 4px 12px rgba(187, 195, 206, 0.6);
					width: 100%;
					border-radius: 5px;
					transition: all ease .3s;
					span{
						text-transform: capitalize;
						font-size: 14px;
						font-weight: 600;
					}
				}
			}
		}
		.imagem{
			flex: 1;
		}
	}
</style>